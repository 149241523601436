import React from 'react';
import SurveyView from './features/survey/SurveyView';

function App() {
    return (
        <div className="App">
            <SurveyView />
        </div>
    );
}

export default App;
