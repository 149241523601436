import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import config from '../../config.json';
import {
    Answer,
    SurveyCredentials,
    SurveyData,
    SurveyState,
    //SurveyTokenCredentials,
} from './types';

const initialState: SurveyState = {
    isLoading: false,
    loaded: false,
    error: false,
    errorCode: 0,
    memberExternalId: '',
    surveyId: null,
    token: null,
    data: {
        id: 0,
        complete: false,
        title: '',
        html: {
            header: '',
            footer: '',
            complete: '',
        },
        showQuestionsOnOnePage: false,
        canEditAnswers: false,
        questions: [],
        answers: [],
    },
};

export const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<SurveyCredentials>) => {
            if (action.payload.memberExternalId !== undefined) {
                state.memberExternalId = action.payload.memberExternalId;
            }
            if (action.payload.surveyId !== undefined) {
                state.surveyId = action.payload.surveyId;
            }
            if (action.payload.token !== undefined) {
                state.token = action.payload.token;
            }
        },
        startLoading: (state) => {
            state.isLoading = true;
            state.loaded = false;
            state.error = false;
        },
        onLoadSurveySuccess: (state, action: PayloadAction<SurveyData>) => {
            state.isLoading = false;
            state.loaded = true;
            state.error = false;
            state.data = { ...action.payload };
            state.data.questions.sort((a, b) => {
                return a.id < b.id ? -1 : 1;
            });
        },
        onLoadSurveyError: (state, action: PayloadAction<number>) => {
            state.isLoading = false;
            state.loaded = false;
            state.error = true;
            state.errorCode = action.payload;
        },
    },
});

export const {
    setCredentials,
    startLoading,
    onLoadSurveySuccess,
    onLoadSurveyError,
} = surveySlice.actions;

export const loadSurvey = createAsyncThunk(
    'survey/load',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(startLoading());

        try {
            const state: any = thunkAPI.getState();
            const surveyId =
                state.survey.surveyId === null ? 'one' : state.survey.surveyId;
            const memberExternalId = state.survey.memberExternalId;

            const response = await axios.get(
                `${config.api.url}/surveys/${surveyId}/`,
                {
                    params: {
                        member_external_id: memberExternalId,
                        token: state.survey.token,
                    },
                }
            );

            if (response.status === 200) {
                const surveyData = response.data.survey;
                const complete = response.data.complete;
                thunkAPI.dispatch(
                    onLoadSurveySuccess({
                        id: surveyData.id,
                        complete,
                        title: surveyData.title,
                        html: {
                            header: surveyData.htmlHeader,
                            footer: surveyData.htmlFooter,
                            complete: surveyData.htmlComplete,
                        },
                        showQuestionsOnOnePage:
                            surveyData.showQuestionsOnOnePage,
                        canEditAnswers: surveyData.canEditAnswers,
                        questions: surveyData.questions,
                        answers: response.data.answers,
                    })
                );
            }
        } catch (error) {
            let code = 0;
            if (error.isAxiosError === true) {
                code = error.response.status;
                if (error.response.data && error.response.data.reasonCode) {
                    code = error.response.data.reasonCode;
                }
            }
            thunkAPI.dispatch(onLoadSurveyError(code));
        }
    }
);

export const sendAnswer = createAsyncThunk(
    'survey/sendAnswer',
    async (answerData: Answer, { getState }) => {
        const {
            memberExternalId,
            token,
            data,
        } = (getState() as RootState).survey;

        const params =
            token !== null
                ? `token=${encodeURIComponent(token)}`
                : `member_external_id=${memberExternalId}`;
        const apiUrl = `${config.api.url}/surveys/${data.id}/questions/${answerData.questionId}/answer?${params}`;

        if (answerData.answers === null) {
            await axios.delete(apiUrl);
        } else {
            await axios.post(apiUrl, { answers: answerData.answers });
        }
    }
);

export default surveySlice.reducer;
