import React from 'react';
import { RootState } from '../../app/store';
import {
    loadSurvey,
    onLoadSurveyError,
    sendAnswer as _sendAnswer,
    setCredentials,
} from './survey.slice';
import * as SurveyReact from 'survey-react';
import qs from 'qs';
import 'survey-react/modern.css';
import { Answer, SurveyData } from './types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';
import ErrorDisplay from '../../components/ErrorDisplay';
import showdown from 'showdown';
import config from '../../config.json';
import './survey.css';

SurveyReact.StylesManager.applyTheme('modern');

class SurveyView extends React.Component<any, any> {
    sendTimeouts: Record<any, any> = {};

    constructor(props: any) {
        super(props);
        this.state = {
            completed: undefined,
            hideTexts: false,
        };
    }

    sendAnswer(data: any) {
        this.props.dispatch(_sendAnswer(data));
    }

    generateSurveyModel(data: SurveyData): SurveyReact.ReactSurveyModel {
        const questionsTypes: Record<string, number> = {};

        const questionsById: any = {};

        const questionIdHasAnswer: any = {};
        data.answers.forEach((answer: Answer) => {
            questionIdHasAnswer[answer.questionId] = answer;
        });

        const questions = data.questions
            .map((questionData) => {
                const questionId = questionData.id.toString();
                const type = questionData.type;

                questionsById[questionId] = questionData;

                if (!data.showQuestionsOnOnePage && !data.canEditAnswers) {
                    if (questionIdHasAnswer[questionId] !== undefined) {
                        return null;
                    }
                }

                questionsTypes[questionId] = type;

                const newAnswers = [];
                for (let i = 0; i < questionData.answers.length; i++) {
                    const answer = { ...questionData.answers[i] };
                    if (answer.imageLink !== undefined) {
                        const newText =
                            answer.text === undefined ? '' : answer.text;
                        answer.text = `${newText} ![image](${config.uploadDirUrl}/${answer.imageLink})`;
                    }

                    newAnswers.push(answer);
                }

                const options: Record<string, any> = {
                    name: questionId,
                    title: questionData.text,
                    isRequired: true,
                    otherText: 'Другое',
                    hasOther: false,
                    validators: [],
                    choices: newAnswers,
                };

                if (type === 1 || type === 4) {
                    options.type = 'radiogroup';
                }
                if (type === 2 || type === 5) {
                    options.type = 'checkbox';
                }
                if (type === 3) {
                    options.type = 'comment';
                }
                if (type === 4 || type === 5) {
                    options.hasOther = true;
                }

                if (questionData.requiredAnswers > 0) {
                    options.validators.push({
                        type: 'answercount',
                        minCount: questionData.requiredAnswers,
                    });
                }

                return options;
            })
            .filter((question) => question !== null);

        const survey = new SurveyReact.Model({
            locale: 'ru',
            title: data.title,
            completedHtml: data.html.complete,
            showPrevButton: data.canEditAnswers,
            sendResultOnPageNext:
                !data.showQuestionsOnOnePage && !data.canEditAnswers,
            showPageTitles: false,
            showQuestionNumbers: 'off',
            questionsOnPageMode: data.showQuestionsOnOnePage
                ? 'standard'
                : 'questionPerPage',
            pages: [
                {
                    elements: questions,
                },
            ],
        });

        const converter = new showdown.Converter();
        survey.onTextMarkdown.add(function (survey, options) {
            let str = converter.makeHtml(options.text);
            str = str.substring(3);
            str = str.substring(0, str.length - 4);
            options.html = str;
        });

        if (this.state.completed || data.complete) {
            survey.setCompleted();
        }

        const surveyData: Record<number, any> = {};
        data.answers.forEach((answer) => {
            if (
                (questionsTypes[answer.questionId] === 1 ||
                    questionsTypes[answer.questionId] === 4 ||
                    questionsTypes[answer.questionId] === 3) &&
                answer.answers !== null
            ) {
                surveyData[answer.questionId] = answer.answers[0];
            } else {
                surveyData[answer.questionId] = answer.answers;
            }
        });
        survey.data = surveyData;

        const onAnswerAccepted = (questionId: number, type: string) => {
            clearTimeout(this.sendTimeouts[questionId]);
            this.sendTimeouts[questionId] = setTimeout(() => {
                let answersData = survey.getAllValues()[questionId];

                if (answersData === undefined) {
                    this.sendAnswer({ questionId, answers: null });
                    return;
                }

                if (type === 'checkbox') {
                    const answers = [...answersData];
                    const comment = survey.getComment(questionId.toString());

                    const otherIndex = answers.indexOf('other');
                    if (otherIndex !== -1) {
                        answers.splice(otherIndex, 1);
                    }

                    if (comment.trim() !== '') {
                        answers.push(comment);
                    }

                    if (answers.length === 0) {
                        this.sendAnswer({ questionId, answers: null });
                        return;
                    }

                    this.sendAnswer({ questionId, answers });
                } else if (type === 'radiogroup') {
                    if (answersData === 'other') {
                        answersData = survey.getComment(questionId.toString());
                    }

                    this.sendAnswer({
                        questionId,
                        answers:
                            answersData.trim() === '' ? null : [answersData],
                    });
                } else {
                    this.sendAnswer({ questionId, answers: [answersData] });
                }
            });
        };

        if (data.showQuestionsOnOnePage || data.canEditAnswers) {
            survey.onValueChanged.add((_, options) => {
                const questionId = options.question.name;
                const type = options.question.getType();
                onAnswerAccepted(parseInt(questionId, 10), type);
            });
        } else {
            survey.onCurrentPageChanged.add(() => {
                const prevPageNumber = survey.currentPageNo - 1;
                const prevQuestion = survey.getPage(prevPageNumber)
                    .questions[0];

                onAnswerAccepted(
                    parseInt(prevQuestion.name, 10),
                    prevQuestion.getType()
                );
            });
            survey.onComplete.add(() => {
                const prevPageNumber = survey.pageCount - 1;
                const prevQuestion = survey.getPage(prevPageNumber)
                    .questions[0];

                onAnswerAccepted(
                    parseInt(prevQuestion.name, 10),
                    prevQuestion.getType()
                );
            });
        }

        survey.onComplete.add(() => {
            this.setState({
                completed: true,
            });
        });

        return survey;
    }

    componentDidMount() {
        if (
            this.props.survey.isLoading === false &&
            this.props.survey.error === false &&
            this.props.survey.loaded === false
        ) {
            const query = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });
            const memberExternalId = query['member_external_id']
                ? query['member_external_id'].toString()
                : undefined;
            const surveyId = query['survey_id']
                ? parseInt(query['survey_id'].toString(), 10)
                : undefined;
            const token = query['token']
                ? query['token'].toString()
                : undefined;

            this.props.dispatch(
                setCredentials({
                    memberExternalId,
                    surveyId,
                    token,
                })
            );

            if (
                (memberExternalId === undefined || surveyId === undefined) &&
                token === undefined
            ) {
                this.props.dispatch(onLoadSurveyError(400));
            } else {
                this.props.dispatch(loadSurvey());
            }

            const iframeMode = query['iframe'] !== undefined ? true : false;
            if (iframeMode) {
                this.setState({
                    hideTexts: true,
                });
            }
        }
    }

    render() {
        const errorDiv = this.props.survey.error ? (
            <ErrorDisplay errorCode={this.props.survey.errorCode} />
        ) : null;
        const loadingDiv = this.props.survey.isLoading ? <Loader /> : null;
        let loadedDiv = null;
        if (this.props.survey.loaded === true) {
            loadedDiv = (
                <React.Fragment>
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                !this.state.hideTexts &&
                                !this.props.survey.data.complete &&
                                !this.state.completed
                                    ? this.props.survey.data.html.header
                                    : '',
                        }}
                    />
                    <SurveyReact.Survey
                        model={this.generateSurveyModel(this.props.survey.data)}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                !this.state.hideTexts &&
                                !this.props.survey.data.complete &&
                                !this.state.completed
                                    ? this.props.survey.data.html.footer
                                    : '',
                        }}
                    />
                </React.Fragment>
            );
        }
        return (
            <div>
                {errorDiv}
                {loadingDiv}
                {loadedDiv}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    survey: state.survey,
});

export default withRouter(connect(mapStateToProps)(SurveyView));
