import React from 'react';
import './error-display.css';

function getErrorMessage(code: number) {
    switch (code) {
        case 400:
            return 'Неверный запрос';

        case 401:
            return 'Нет доступа';

        case 403:
            return 'Нет доступа к опросу';

        case 404:
            return 'Опрос не найден';

        case 1001:
            return 'Опрос ещё не готов';

        case 1002:
            return 'Опрос ещё не начался';

        default:
            return 'Проверьте доступ к интернету или повторите попытку позже.';
    }
}

export default function ErrorDisplay(props: any) {
    return (
        <div className="error-display">
            <h2>Ошибка</h2>
            <div className="error-display__description">
                {getErrorMessage(props.errorCode)}
            </div>
        </div>
    );
}
